import { template as template_8bee4d9413864848b21037b48e90fb53 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8bee4d9413864848b21037b48e90fb53(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
