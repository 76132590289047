import { template as template_d8c89f6291e242e59d43d5dd3450a28a } from "@ember/template-compiler";
const FKLabel = template_d8c89f6291e242e59d43d5dd3450a28a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
